
/* ap for airports-pilots */

/*
/page
div ap-page
  div select card
  div window
    div ap-titles
      div ap-title-name
      div ap-title-location
      div ap-title-date 

  div /card
  div /button


/card
  div ap-card
    button ap-edit-modal-btn
      div ap-info 
        div ap-name
        div ap-location
        div ap-date


/button
  ap-btn-container
    button ap-add-btn
      p ap-add-btn-text
*/

.ap-page {
  display: flex;
  flex-direction: column;
}

.ap-select-item {
  margin: 1.5rem 7rem 0rem;
  font-family: var(--font-family-main);
}

.ap-window {
  margin: 0.5rem auto;
  width: 56rem;
  height: 34rem;
  background-color: var(--card-background-color);
  box-shadow: var(--box-shadow);
}

.ap-pilot-titles {
  margin: 3rem auto 0rem;
  width: 44rem;
  display: flex;
  justify-content: space-between;
}

.ap-airport-titles {
  margin: 3rem auto 0rem;
  width: 41.5rem;
  display: grid;
  grid-template-columns: 15rem 19.7rem 15rem; 
  font-weight: 500;
  font-size: 14px; 
  font-family: var(--font-family-main);
}

.ap-airport-title-name {
  margin: auto;
  width: 100%;
}

.ap-airport-title-loaction {
  margin: auto auto auto 2.5rem;
  width: 100%;
}

.ap-airport-title-date {
  margin: auto;
  width: 100%;
}

.ap-card {
  margin: 1rem auto 0rem;
  display: flex;
  width: 46rem;
  height: 2.5rem;
  background-color: var(--ap-card-background-color);
  border: var(--card-border);
}

/*
.ap-info {
  display: flex;
  justify-content: space-between;
}
*/

.ap-pilot-info {
  width: 52rem;
  display: grid;
  grid-template-columns: 15rem 1fr 1fr;  
  font-size: 14px;
}

.ap-pilot-name-container {
  display: flex;
  width: 15rem;
  margin: auto;
}

.ap-pilot-image {
  height: 2rem;
  clip-path: circle();
  margin: auto 0rem;
}


.ap-pilot-location {
  margin: auto;
  width: 100%;
}

.ap-pilot-date {
  margin: auto 0rem auto 0rem;
  width: 100%;
}

.ap-airport-info {
  width: 52rem;
  display: grid;
  grid-template-columns: 10rem 24rem 15rem;  
  font-size: 14px;
}

.ap-airport-name { 
  margin: auto;
}

.ap-airport-location {
  margin: auto auto auto 8.3rem;
}

.ap-airport-date {
  margin: auto 0rem auto 3rem;
  width: 100%;
}

.pilot-btn-container{
  margin-top: 13rem;
  width: 56rem;
  display: flex;
}

.airport-btn-container {
  width: 56rem;
  display: flex;
}

.ap-add-btn {
  margin: auto;
  height: 2.5rem;
  width: 10.250rem;
  display: flex;
  background-color: #498FEB;
  border: .07rem solid #E6E6E6;
  border-radius: 5px;
}

.ap-add-btn-text {
  margin: auto;
  color: white;
  font-family: var(--font-family-main);
  font-weight: 300;
}