
.flights-page{ 
  min-height: 100vh; /* this made footer go to bottom of
                       page even on scroll */
  display: flex;
  justify-content: center;
}

.page {
  display: flex;
  flex-direction: column;
  width: 85.6rem;
}

/*
.flights-page{ 
  min-height: 100vh; 
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}

.page {
  display: flex;
  flex-direction: column;
  width: 85.6rem;
}*/


.flights {
   /*margin-left: 2rem;
   margin-top: 1.35rem;
   padding-bottom: 20rem; /* currenlty keeping pilots form touching bottom*/
   /*width: 65rem; */

   display: grid;
   grid-template-columns: 1fr 1fr 1fr;  
   margin: 0rem auto;
}
/*
@media (max-width: 2240px) {
  .flights {
    grid-template-columns: 1fr 1fr;
  }
}*/

@media (max-width: 580px) {
  .flights {
    grid-template-columns: 1fr;
  }
}

.add-flight-area {
  margin: 2rem auto 2rem;
}

