
.nav {
  background-color: #FFFFFF;
  height: 4.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2.4px solid var(--border-color-altdarker2);
}

 /* 
.title {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 12rem;
  height: 3.8rem;
  color: var(--accent-blue);
}
*/

.nav-title {
  margin-left: 7rem;
  display: flex;
}

@media (max-width: 580px) {
  .nav-title {
    margin-left: 3rem;
  }
}

.nav-logo-img {
 height: 1.2rem;
 margin: auto .1rem;
}

.nav-title-initials {
  color: var(--accent-blue);
  font-size: 26px;
  font-weight: 700;
  margin-bottom: .1rem;
}

.nav-title-name {
  color: var(--accent-blue);
  font-size: 24px;
  font-weight: 400;
  margin: auto auto .13rem .3rem;
}

@media (max-width: 1130px) {
  .nav-title-name {
    display: none;
  }
}

/*
@media (max-width: 580px) {
  .title {
    margin-left: 3rem;
  }
}
  */

.heading{ 
  font-family: var(--font-family-main);
  font-size: 28px;
  font-weight: 500;
  /*-webkit-text-stroke:  1px var(--border-color-altdarker2);*/
  margin: auto;
}

.heading-underline {
  display: flex;
  width: 11.6rem;
  height: 2.2rem;
  border-bottom: 2px solid var(--accent-blue);
}

.subheading{
  font-family: var(--font-family-main);
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  margin-left: 4rem;
  margin: auto;
}


.nav-links {
   font-family: var(--font-family-main);
   font-weight: 300;
   font-size: 16px;
   margin-right: 6rem;
   display: flex;
}

@media (max-width: 580px) {
  .nav-links {
    margin-left: 5rem;
  }
}

.nav-links li {
    list-style: none;
    margin-left: 6rem;
    display: block;
}

.hamburger-menu-icon-container {
  margin-left: 12rem;
}

@media (min-width: 580px) {
  .hamburger-menu-icon-container {
    display: none;
  }
}

@media (max-width: 580px) {
  .nav-links li {
    display: none;
  }
}

.hamburger-menu-window {
  margin-right: 70rem;

  background-color: white;

  width: 50vw; /*100% of weiwport width*/
  height: 50vh; /*100% of weiwport height*/
  top: 0;
  left: 50vw;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.hamburger-menu-nav-links li {
  list-style: none;
  margin-top: 1rem;
}

.hamburger-menu-nav-links li:hover {
  color:blue;
}

a { /*link styling*/
  text-decoration: none; /*removes the link underline*/
  color: #000000;
}


@media (max-width: 1164px) {
  .nav-links {
    font-size: 17px;
   }
}

/*
@media screen and (max-width:651px) {
    .logo-name  {
        top: 20px;
        position: fixed;
        scale: 1.2;
        z-index: 3;
    }
}
*/

