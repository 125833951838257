
.modal, .overlay{
  width: 100vw; /*100% of weiwport width*/
  height: 100vh; /*100% of weiwport height*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.overlay {
  background-color: #3232328c;
}

.modal-title {
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  height: 2rem;
  font-size: 24px;
  background-color: var(--tertiary-dark-color);
  font-family: var(--font-family-main);
  color: var(--primary-light-color);
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* modal buttons  */

.modal-exit-btn {
  margin-right: 1.5rem;
  height: 1.4rem;
  width: 1.7rem;
  font-size: 17px;
  font-weight: 500;
  border: .07rem solid black;
  /*background-color: #dfd2cd;
  background-color: #ffe3d6;*/
  background-color:#FEE9DD;
  border-radius: 4px;
}

.modal-exit-btn:hover {
  cursor: pointer;
  border: 2px solid #e2e209a7; 
}

.modal-bottom-btns {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.32rem;
  height: 1.7rem;
}


.edit-btn  {
  width: 3.8rem;
  /*background-color: rgb(186, 225, 244);*/
   
 /*background-color: #ffffff7d;/*plain
  background-color: #a9e5fbe0;  last one
 */
  /* background-color:#535878;*/
 
/*  background-color: rgba(0, 0, 0, 0.6);*/
  color: #FEE9DD;
  
  /*background-color: #676767; lighter*/  
  /*background-color: rgba(0, 0, 0, 0.74); inbetween*/


  background-color: #383838; 
  /*background-color: #00145e;*/
  border: 1px solid black;
  border-radius: .2rem;
}

.delete-btn {  
  width: 3.8rem;
  /*background-color: rgb(252, 183, 183);*/
  /*background-color: rgb(255, 170, 170); last */
 
   /*background-color: #c15e55; lighter*/
 
  background-color: #dc2d2d;
  /*background-color: #870202;*/
  color: #FEE9DD;
  border: 1px solid black;
  border-radius: .2rem;
}

.edit-btn:hover + .delete-btn:hover {
  cursor: pointer;
}

