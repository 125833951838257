/*
html, body {
    height: 100vh;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: var(--primary-dark-color);
}
*/

.p {
    border: 1px solid black;
}

.page-layout {

    
    display: flex;
    justify-content: space-evenly; /*centers horizontally*/
    /*align-items: center; centers vertically*/
   
}

.flight-info-card-create {
    margin-top: 2rem;
    width: 65%;
    height: 32rem;
    background-color: var(--primary-light-color);
    /*background-color: #f8ebe5;*/
    border-bottom: 7px solid var(--secondary-dark2-color);
    display: flex; 
    justify-content: space-evenly;
}

.flight-info-card-update {
  margin-top: 10rem;
  width: 64rem;
  height: 30rem;
  background-color: var(--primary-light-color);
  /*border-bottom: 7px solid var(--secondary-dark2-color);*/
  display: flex; 
  justify-content: space-evenly;
  border-radius: 30px;
}

.flight-number {
   height: 2.1rem;
   font-size: 45px;
   background-color: var(--tertiary-dark-color);
   font-family: var(--font-family-main);
   font-weight: 500;
   display: flex;
   justify-content: center;
   align-items: center;
}



.flightNumber-label {
    color: var(--primary-light-color);
    margin-right: 10px;
    font-size: 25px;
}

.flightNumber-input {
    height: 19px;
    width: 60px;
    font-size: 15px;
}

.flight-titles {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.titles {
    width: 50%;
    text-align: center;
}

.flight-information {
    display: flex;
}



.form {
    height: 100%;
    width: 100%;
}


.info-column {
    width: 50%;
    height: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;  
}


.item {  
    /*border: 1px solid black;*/
    margin-left: 7rem;
}

.departure-airport{
  display: flex;
}

.center-line {
    width: 0px;
    border-right: 1px solid black;    
    height: 240px;
    margin-top: 25px;
}
 

label {
    color: black;
    font-size: 18px;
    font-family: var(--font-family-main);
    font-weight: 500;
    margin-right: 12px;
}

.flight-input {
  height: 2.1rem;
  /*border: .05rem solid #783E3E;*/
  border: none;
  border-bottom: .01rem solid black;
  background-color: transparent;
  width: 17rem;
  /*border-radius: .5rem;
  border-radius: .5rem;*/
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: none;
  /*background-color: #FEE9DD;
  background-color: #f7efeb;*/
  /*color: var(--primary-light-color);
  color-scheme: dark;*/
}


/* previous flight-input
.flight-input {
  height: 3rem;
  border: .05rem solid #783E3E;
  old-border: .01rem solid rgb(147, 147, 147);
  width: 8rem;
  border-radius: .5rem;

  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #FEE9DD;

}
*/


/*
.arrivalTime-input:before {
  content: 'Arrival Time';
  width: 120px;
  border: 1px solid red;
}
*/
/*
.arrivalTime-input:invalid:before {
  content:'Arrival Time';
  margin-right:.6em;
  color:#9d9d9d;
}
*/
/*
.arrivalTime-input:before {
  content: 'Arrival Time';
  position: absolute;
  background: #fff;
  width: 6rem;
}*/
/*
.arrivalTime-input:before {
  content: 'Arrival Time';
  position: absolute;
  background: #fff;
  width: 6rem;
}*/


/*departure date input          */

.departureDate-input {
  display: flex;
  padding-right: 1.2rem;
}

.departureDate-input:before {
  -moz-appearance: initial; 
  content: 'Departure Date';
  position: relative;
  white-space: nowrap; /*keeps text on one line*/
  right: .7rem;
  margin: auto;
  z-index: 0;
}

.departureDate-input:focus:before {
  -moz-appearance: initial; 
  width: 0rem;
  content: '';
}



/*arrival date input          */

.arrivalDate-input {
  display: flex;
  padding-right: 1.2rem;
}

.arrivalDate-input:before {
  content: 'Arrival Date';
  position: relative;
  white-space: nowrap; /*keeps text on one line*/
  right: 1.5rem;
  margin: auto;
  z-index: 0;
}

.arrivalDate-input:focus:before {
  width: 0rem;
  content: '';
}




/*departure time input          */

.departureTime-input {
  display: flex;
  padding-right: 1.2rem;
}

.departureTime-input:before {
  content: 'Departure Time';
  position: relative;
  white-space: nowrap;
  right: 1.7rem;
  margin: auto;
  z-index: 0;
}

.departureTime-input:focus:before {
  width: 0rem;
  content: '';
}


/*arrival time input        */

.arrivalTime-input {
  display: flex;
  padding-right: 1.2rem;
}

.arrivalTime-input:before {
  content: 'Arrival Time';
  position: relative;
  white-space: nowrap;
  right: 2.1rem;
  margin: auto;
  z-index: 0;
}

.arrivalTime-input:focus:before {
  width: 0rem;
  content: '';
}


.form-bottom {
    display: flex;   
    height: 5rem;
}

.pilot-dropdown {
  margin: auto 7rem auto;
}

.add-flight {
  margin: 3rem 18rem;
}

.add-flight-btn {
    width: 90px;
    background-color: #e8dad5;
    border: 1px solid black;
    border-radius: 3px;
    cursor: hover;
    margin: auto;
}

.add-flight:hover {
  cursor: pointer;
}

.update-form-bottom {
  display: flex;  
  justify-content: space-around; 
  height: 5rem;
}

.update-flight-btn {
  width: 90px;
  background-color: #e8dad5;
  border: 1px solid black;
  border-radius: 3px;
  cursor: hover;
  margin: auto;
  height: 2rem;
}

.update-flight:hover {
  cursor: pointer;
}
