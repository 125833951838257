
.dropdown {
  position: relative;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  width: 17.4rem;
  padding: .7rem;
  background-color: #FEE9DD;
  border: none;
  border-bottom: .01rem solid black;
  /*border-radius: .5rem;*/
  /*box-shadow: #959da533
  0px 8px 24px;*/
  cursor: pointer;
  height: 1.6rem;
  padding-left: 1.2rem;
  /*border: .01rem solid rgb(147, 147, 147); origianl border
  border: .05rem solid #783E3E;*/
  
}

.dropdown-btn-open {
  border: 2px solid rgb(147, 197, 253);
}

.dropdown-toggle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10rem;
  position: absolute;
  left: 7rem;
  top: 1rem;
}

.toggle-icon-click-open {
  height: .9rem;
  width: 1.1rem;
  position: absolute;
  left: 16.9rem;
  top: 1rem;
  /*border: 1px solid black;*/
}

.dropdown-content {
  position: absolute;
  z-index: 1;
  /*min-width: 100%;*/
  border-radius: .5rem;
  width: 17.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: .5rem;
  /*background-color: #fff2eb;*/
  background-color: #fff9f6;
  box-shadow: rgba(149, 157, 165, 0.2)
  0px 8px 24px;
  max-height: 30vh; /*so it never goes outside screen*/
  overflow-y: scroll;
  scrollbar-width: none; /*hide scroll bar*/
  -ms-overflow-style: none; /*to work on every browser*/ 
  opacity: 0; /*not visible unless open*/
  transform: translateY(-5%);
  transition: transform 150ms ease-in-out, /*adds slight motion down*/
  opacity 100ms ease-in-out;
  pointer-events: none;/*ALLOWS CURSOR TO NOT CLICK ON CONTENT WHEN CLOSED
  without this, content will cover lower buttons even though not visible*/
}

/*additional styles to make sure scrollbar doesn't appear*/
.dropdown-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.dropdown-content-open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all; /*ALLOWS CURSOR TO NOT CLICK CONTENT WHEN OPEN*/
}

.dropdown-item {
  padding: .5rem;
  margin: .1rem;
  width: 100%;
  border-radius: .5rem;
  cursor: pointer;
} 

.dropdown-item:hover {
  background-color: #fffaa4bb; 
}

