
.flight-button-component{
  display: flex;
}

.flight-button-title{
  width: 5.8rem;
  height: 1.6rem;
  font-size: 18px;
  color: white;
  font-family: var(--font-family-main);
  font-weight: 300;
  display: flex;
  margin: .5rem auto;
}

.add-flight-button{
  display: flex;
  justify-content: space-around;
  height: 2.5rem;
  width: 10.250rem;
  margin-right: .1rem;
  margin-top: .05rem;
  background-color: #498FEB;
  border: .07rem solid #E6E6E6;
  border-radius: 5px;
}

