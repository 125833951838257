

.edit-flight-modal {
  width: 24rem;
  height: 22rem;
  /*width: 22rem;
  height: 23.1rem;*/
  background-color: var(--primary-light-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  display: flex; 
  flex-direction: column;
  justify-content: space-around;
}

.edit-flight-modal-title {
   font-weight: 400; /*thinner font*/
}

.edit-flight-title {
  margin: auto auto auto 8.2rem; /*centers*/    
}  

.edit-flight-info-container {
  margin: auto;
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
  width: 23rem;
}

.edit-flight-info {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.edit-flight-airport-info {
  display: flex;
  flex-direction: column;
  /*width: 22.85rem;*/
  width: 21rem;
  /*width: 20rem;*/
  border-top: .01rem solid black;
  /*margin-right: .97rem;
  margin-top: .4rem;*/
  margin: auto;
}

.edit-flight-airport-code {
  font-family: var(--font-family-main);
  font-weight: 600;
  margin: auto; 
  margin-top: 1rem;
  font-size: 25px;
  display: flex;
  
}

.edit-flight-airport-city-state {
  margin: auto;
  margin-top: .5rem;
}

.edit-plane-img-container {
  margin: auto;
  /*padding-right: 0.4rem;
  margin-bottom: 2rem;*/
  padding-left: 2rem;
  margin-bottom: .6rem;
}

.plane-icon {
  /*width: 19.5rem;*/
  width: 20.5rem;
  /*opacity: .62; */
  opacity: .77; 
  
}

.edit-plane-img {

  width: 15rem;
  /* reverse
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);*/
}

.flight-edit-btn {  
  margin-right: 4rem;
}

.flight-delete-btn  {
  margin-left: 5rem;
}

