

.airport-modal {
  width: 23.5rem;
  height: 25.2rem;
  background-color: var(--primary-light-color);
  display: flex; 
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
}

.edit-airport-title {
  margin: auto auto auto 9.38rem; /*centers*/ 
}

.update-airport-title {
  margin: auto auto auto 6.6rem; /*centers*/    
}  

.add-airport-title{
  margin: auto auto auto 7.5rem; /*centers*/  
}

.airport-image-container {
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: .85rem;
  display: flex;
  justify-content: space-around;
  width: 18rem;
}

.airport-icon-old {
  width: 12.5rem;
 /* opacity: .6; original
    opacity: .74; in-between*/
  opacity: .8;  
}

.airport-image {
  width: 12rem;
  opacity: .7;
}

.airport-modal-info {
  width: 22.3rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0rem; 
  padding-top: .25rem; 
  padding-bottom: .4rem; 
  border-top: 1px solid black;
}


/*  line around airport 
.airport-image-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-around;
  border-right: 1px solid black;
  border-left: 1px solid black;
  width: 18rem;
  margin: auto;
}

.airport-image {
  width: 12rem;
  opacity: .7;
}

.airport-modal-info {
  border-top: 1px solid black;
  width: 18rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0rem;
}
*/



.airport-modal-code {
  margin: .5rem auto 0rem; /*top 1, left/right auto bottom 0 */
  height: 1rem;
  font-family: var(--font-family-main);
  font-weight: 600;
  margin-bottom: 1.2rem;  
  font-size: 25px;
  
}

.airport-modal-code p {
  margin: auto;
}

.airport-modal-city-state {
  height: 2rem;
  margin: .2rem auto;
  font-family: var(--font-family-main);
  font-weight: 400;

}

.airport-modal-city-state p {
  margin: auto;
}

.airport-modal-form {
  margin: 0rem auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 19rem;
  height: 18rem;
  padding-top: 3.5rem;
}

.airport-input {
  width: 17rem;
  height: 3rem;
  border-radius: .5rem;
  border: .05rem solid #783E3E;
  background-color: #FEE9DD;
  padding-left: 1rem;
  margin-top: 1.1rem;
}

/* input placeholder text styles 
   can mainly use top 2 */

::placeholder { /* Most modern browsers support this now.*/ 
  color: black;
  opacity: 1 !important; /*important makes it darker like normal */
  padding-left: 0rem;
}

::-webkit-input-placeholder { /* WebKit browsers */
  color: black;
  opacity: 1 !important;
  padding-left: 0rem;
}


.airport-code {
  display: flex;
  width: 18rem;
  justify-content: space-between;
}

.airport-code-input {
  width: 6.7rem;
  height: 3rem;
  border-radius: .5rem;
  border: .05rem solid #783E3E;
  background-color: #FEE9DD;
  padding-left: 1rem;
}

.code-link {
  color: blue;
  margin-right: 2rem;
  margin-top: 1rem;
  font-size: 18px;
}

.airport-city {
  display: flex;
  width: 24rem;
}

.airport-city-input {

}

.airport-state {
  display: flex;
  width: 24rem;
}

.airport-state-input {

}

.airport-edit-btn {  
  margin-right: 4rem;
}

.airport-delete-btn  {
  margin-left: 4rem;
}

.add-airport-submit {
  display: flex;
  justify-content: space-around;
}

.add-airport-submit-btn {

 margin-top: 3rem;
 margin-bottom: 1.32rem;
 height: 1.9rem;
 width: 3.2rem;
 border: 1px solid black;
 border-radius: .2rem;
 background-color: #479866;
 color: white;
}