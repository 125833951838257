
.flight-card {
  margin-top: 3rem;
  margin-left: 3.4rem;
  width: 24.688rem; /* was 97.9%; */
  background-color: var(--card-background-color);
  height: 13.875rem;
  border: var(--card-border);
  /*
  border-left: 5px solid #0029FF;
  border-right: 5px solid #0029FF;*/
  border-radius: 21px;
  box-shadow: var(--box-shadow);
}

@media (max-width: 580px) {
  .flight-card {
    width: 23rem;
    height: 13em;
    margin-left: 1.2rem;
  }
}


.flight-card:hover {
  /*background-color: #ffff8e87;*/
  background-color: #b2d2ff; 
  background-clip: padding-box;
  cursor: pointer;
}

/*
.flight-card:hover .flightnumber{
  background-color: transparent;
  color: var(--secondary-text-color);
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
*/


.flight {
  display: flex;
  height: 100%;
}

.flight-info {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.flight-header {
  margin-top: 0rem;
  display: flex;
  justify-content: space-between;
}

.flightnumber {
  margin: auto;
  margin-left: 1.7rem;
  font-size: 18px;
  display: flex;
  text-align: center;  
  font-family: var(--font-family-main);
  font-weight: 500;
  width: 6.2rem;
  height: 1.8rem;
  border: 1px solid var(--accent-blue);
  border-radius: 4px;
}

.flightnumber p  {
  margin: auto; /*centers*/    
}  

.flight-card-airports {
  margin-bottom: 0rem;
  width: 11rem;
  font-family: var(--font-family-main);
  font-weight: 400;
  font-size: 18px;
  display: flex;
  height: 2.5rem;
 }
/*
 .aiprort-icon-container {
transform: scale(.3);
width: 2rem;
 }
*/

.flight-airport-names {
  display: flex;
  margin: auto;
}

.departure-airport-name {
  margin-right: 0rem;
  width: 4.1rem;
}

/*
.arrow-icon {
  margin-top: .1rem;
  fill: var(--secondary-text-color);
  width: 2.2rem; 
  margin-left: .6rem;   
}
  */

.arrow-airports {
  border: 2px solid red;
}

.arrival-airport-name {
  margin-right: 1.6rem;
}

.flight-details{
  margin-top: 2.7rem;
  display: flex;
  justify-content: space-between;
  height: 7rem;
}

@media (max-width: 580px) {
  .flight-details{
    margin-top: 1.8rem;
  }
}

/*
.pilot-departure-arrival {
  margin-left: 1.7rem;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  margin-right: 1.8rem;
  height: 2.2rem;
  border: 1px solid black;
}
  */

.flight-details-headers {
  display: flex;
  flex-direction: column;
  width: 14.7rem;
  margin-left: 1.7rem;
}

.flight-details-title {
  display: flex;
  height: 2.2rem;
}

.flight-details-header-title{
  font-weight: 500;
  font-size: 14px;
  margin-left: .54rem;
}

.date-icon-container {
  margin-top: .6rem;
  margin-right: .4rem;
}

/*
.date-time-info {
  display: flex;
}
  */

.flight-details-content {
  display: flex;
  flex-direction: column;
  width: 19rem;
  height: 7rem;
}

.flight-pilot-name {
  margin-left: 5.5rem;
}

.pilot-date-time{
  display: flex;
  height: 2.2rem;
  font-size: 14px;
  font-weight: 400;
}


.pilot-departure-arrival p {
 
 
}

.arrival {
  
}


.flight-card-icon {
  display: flex;
  justify-content: center;
  margin-right: 1.5rem;
}

.flight-plane-icon {
   margin: auto;
   width: 4rem;
   padding-top: 1.8rem;
   fill: var(--secondary-dark2-color);
   
}
















.flight-buttons {
  margin-top:1rem;
  width: 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.btn {
  width: 2.2rem;
  height: 20rem;
  border: 1px solid var(--tertiary-dark2-color);
  margin-bottom: 1rem;
  
}

.edit {
  margin-top: 3.3rem;
  background-color: #e8dad5;
  /*background-color: var(--flight-edit-button-color);*/
}

.edit-icon {   
  height: 1rem;
  width: 20px;
}

.edit:hover{
  /*background-color: #9dbcfa;*/
  /*background-color: #b9d0ff7a; last one*/
  background-color: #d5d5da;
  cursor: pointer;
}

.edit:hover .edit-icon {
  fill: #070092;
}


.delete {
  margin-bottom:3rem;
  font-weight: 600;
  background-color: var(--primary-dark-color);
  /*background-color: var(--flight-delete-button-color);*/
}


.delete:hover {
  /*background-color: #ff9090;*/
  /*background-color: #fbbfbf; last one*/
  background-color: #ff777778;
  cursor: pointer;
  color: #6d2323;
}