:root {

  --accent-blue: #4A629F;
  --logo-blue: #0029FF;
  --card-background-color: #fafafa;
  --card-border: 1px solid #E6E6E6;
  --ap-card-background-color: #FFFFFF;
  --box-shadow: 0px 4px 65px rgba(20, 102, 142, 0.18);
  

  --primary-light-color: #FEE9DD;
  --primary-medium-color: #F8D7D3;
  --primary-dark-color: #D5BFB6;
  --secondary-light-color: #9DB0CE;
  --secondary-dark-color: #3B395B;
  --secondary-dark2-color: #535878;
  --tertiary-light1-color: #C68E8E;
  --tertiary-light2-color: #D67878;
  --tertiary-dark-color: #783E3E;
  --tertiary-dark2-color:#430E0E;

  --secondary-text-color: #4B2222;

  --flight-edit-button-color: #B5C7EA;
  --flight-delete-button-color: #FFA5A5;

  --font-family-main:"Inter", sans-serif;
  --font-title-main:"EB Garamond", sans-serif;

  --font-size-small: 1.2rem;
  --font-size-medium: 1.6rem; 
  --font-size-large: 2.4rem;
  --font-size-xlarge: 3.2rem;

  --spacing-small: 1.2rem;
  
  --border-radius-small: 0.4rem;
   

}

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*height: 100vh;*/
    min-width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #EDF1F5;
    
    /*
    background: rgb(0,41,255);
    background: linear-gradient(180deg, rgba(0,41,255,1) 0%, rgba(237,241,245,1) 8%);
    */

    display: flex; 
    flex-direction: column; 
    min-height: 100vh;


    /*website looked better zoomed out a bit*/
    /* transform: scale(0.95); Scale down to 95% */ 
     /* transform-origin: top left;Set the origin point for scaling */ 
    /* width: 105.11%; Adjust the width to prevent clipping */ 
   /*  overflow-x: hidden; Remove scrollbar this produced */
  }
  

  
/*
@media all (min-width: 400px) {
  html, body {
    width: 100px;
    height: 20%;
    border: 2px solid black;
  }
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

