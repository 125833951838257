
.pilot-icon {
  margin-top: .4rem;
  fill: var(--accent-blue);
  width: 1.2rem;
}

.deparing-flight-icon {
  margin-top: .7rem;
  fill: var(--accent-blue);
  width: 1.3rem;
}

.arriving-flight-icon {
  margin-top: .7rem;
  fill: var(--accent-blue);
  width: 1.3rem;
  transform: rotate(50deg);
}

.hamburger-menu-icon {
  width: 2rem;
  fill: var(--accent-blue);
}

.airport-icon {
 /* transform: scale(.9);*/
  fill: var(--accent-blue);
  margin-left: .5rem;
  width: 2rem;
  /*border: 1px solid blue;
  object-fit: 70% 15%;*/
}

.date-icon {
  transform: scale(.95); /*width was cutting svg off*/
  fill: var(--accent-blue);
  width: 1.5rem;
  height: 1.5rem;
}