
.pilot-modal {
  /*previous size (nothing else changed):
  width: 22rem;
  height: 23.1rem;*/
  width: 24rem;
  height: 24rem;
  background-color: var(--primary-light-color);
  display: flex; 
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
}

.edit-pilot-title  {
  margin: auto auto auto 10.55rem;
}  

.update-pilot-title {
  margin: auto auto auto 6.7rem; /*centers*/    
}

.add-pilot-title {
  padding-left: .4rem;
  width: 10rem;
}

.upload-image-header {
  display: flex;
  justify-content: space-between;
}

.image-preview {
  height: 4rem;
  clip-path: circle();
}

.edit-pilot-image-container {
  margin: auto;  
  margin-top: 3rem;
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-around;
  /*border-right: 1px solid black;
  border-left: 1px solid black;*/
  width: 16rem;
}

.edit-pilot-image {
  opacity: .7;
  width: 9.8rem;
}

.edit-pilot-icon {
  /*fill: #877269;
  fill:rgb(118, 110, 104);
  opacity: .6;*/
  opacity: .78;
  width: 9.8rem;
  margin: 1.5rem auto .8rem;
}

.edit-pilot-modal-info {
  border-top: 1px solid black;
  width: 21rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0rem;
  padding-top: .35rem; 
}

.edit-pilot-name {
  margin: .5rem auto 0rem; /*top 1, left/right auto bottom 0 */
  height: 1rem;
  font-family: var(--font-family-main);
  font-weight: 600;
  margin-bottom: 1rem;  
  font-size: 24px;
}

.edit-pilot-name p {
  margin: auto;
}




.edit-pilot-btn {
  height: 1.6rem;
  width: 4rem;
  margin-left: 16rem;
  margin-bottom: .8rem;
}


.pilot-modal-form {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  width: 18rem;
}

.pilot-modal-form input[type=text] {
  width: 17rem;
  height: 1.5rem;
  line-height: 15px;
}

.update-pilot-btn {
  width: 4rem;
}

.pilot-edit-btn {  
  margin-right: 4rem;
}

.pilot-delete-btn  {
  margin-left: 4rem;
}

.add-pilot-submit-btn {
  width: 4rem;
  height: 1.5rem;
  font-size: 18px;
}


