
.welcome {
  position: absolute;
  top: 11rem;
  left: 7.5rem;
  z-index: 1;
  color: #0029FF;
  font-size: 36px;
  font-weight: 600;
  font-family: var(--font-family-main);
}

.home-img-container {
  width: 100%;
  overflow: hidden;
}

.home-img {
  width: 125%;

  clip-path: inset(4rem 0% 0% 0%); /* Top, right, bottom, left */
  position: relative;
  bottom: 13.5rem;
  margin-bottom: 20rem;
}