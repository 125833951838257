
.about-page {
  width: 100%;
  height: 39rem;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  /*margin-bottom: 1.7rem;*/
}

.about-card {
  background-color: var(--primary-light-color);

  /*border: 3px solid var(--tertiary-dark-color);*/
  width: 30rem; 
  height: 20rem;
  border-bottom: 7px solid var(--secondary-dark2-color);
  margin: auto;
  display: flex;
  flex-direction: column;
}

.about-title {
  font-size: 24px;
  display: flex;
  text-align: center;
  background-color: var(--tertiary-dark-color);
  color: var(--primary-light-color);
  font-family: var(--font-family-main);
  font-weight: 500;
  width: 100%;
  height: 2rem;
}

.about-title p  {
  margin: auto; /*centers*/    
}  

.about-info {
   width: 27rem;
  margin: auto; /*centers*/  
  text-align: center;
  line-height: 1.6;
  font-size: 18px;
 /* font-family: var(--font-family-main); */
  font-weight: 400;
}

.about-info bold {
  font-weight: 700;
}


.about-info p {
  
}

.link-icons {
  /*border: 1px solid black;*/
  display: flex;
  justify-content: space-evenly;
  
  /*fill: #ab6a55;*/
  width: 30%;
  margin: auto;
  margin-bottom: 1rem;
}


.linkedin-anchor  {
  fill: #000000
}

.github-anchor {
  fill: #000000
}

.linkedin-icon {
  width: 2rem;
  /*fill: #827976;*/
}

.github-icon {
  width: 2rem;
}

.linkedin-icon:hover {
  fill: #a88418;
}

.github-icon:hover {
  fill: #a88418;
}

.under-construction {
  top: 74%;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;  
  z-index: 1;
  background-color: var(--primary-light-color);
  border-radius: 25px;
  border: 2px solid var(--tertiary-dark-color);
  width: 14.5rem; 
  height: 2.8rem;
  margin: auto;
  display: flex;
  justify-content: space-around;
}

.under-construction p{
  margin: auto;
}



/*
.about-card-inside {
  background-color: var(--primary-light-color);
  border-radius: 50px;
  border: 3px solid  #757575; 
  width: 30rem; 
  height: 22rem;
  margin: auto;
}
  */